export const MinutesToHours = (time) => {
    let hours = `${time} minutes`;

    if (parseInt(time) > 60) {
        const timeDiff = parseInt(time) - 60;

        hours = `1 hour ${timeDiff} minutes`;
    }

    return hours;
};
