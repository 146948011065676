import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import {
    DateField,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useEditController,
} from "react-admin";

export default function ClassShow(props) {
    const controllerProps = useEditController(props);
    const students = controllerProps?.record?.students_ids
        ? controllerProps?.record?.students_ids
        : [];

    return (
        <React.Fragment>
            <Show {...props} hasedit="false">
                <SimpleShowLayout>
                    <Typography variant="h6">Class Details</Typography>
                    <TextField
                        source="class_name"
                        label="Class Name"
                        emptyText="-"
                    />
                    <TextField
                        source="class_description"
                        label="Description"
                        emptyText="-"
                    />
                    <FunctionField
                        label="Fees"
                        source="amount"
                        render={(record) => {
                            return record?.is_free_class
                                ? "Free class"
                                : record?.configuration?.fee?.amount
                                ? `${record?.configuration?.fee?.amount} £`
                                : `Fees not added!`;
                        }}
                    />
                    <DateField
                        label="Class Conducted on"
                        source="configuration.session_start_time"
                        showTime
                    />
                    <FunctionField
                        label="Age group"
                        source="age_group"
                        render={(record) => {
                            return `${record?.configuration?.age_group} years`;
                        }}
                    />
                    <FunctionField
                        label="Size of class"
                        source="max_students"
                        render={(record) => {
                            return `${record?.configuration?.max_students} students`;
                        }}
                    />
                    <FunctionField
                        label="Session Duration"
                        source="session_duration"
                        render={(record) => {
                            return `${record?.configuration?.session_duration} mins`;
                        }}
                    />
                    <TextField
                        source="teacherName"
                        label="Teacher Name"
                        emptyText="-"
                    />
                    <Typography variant="h6">
                        Students Registered{" "}
                        {`(${
                            students.length > 0
                                ? students.filter((value) => {
                                      return value.booking_status === "SUCCESS";
                                  }).length
                                : 0
                        })`}
                    </Typography>
                    <ol style={{ fontFamily: "sans-serif" }}>
                        {students.length > 0 &&
                            students.map((student) => {
                                return student?.booking_status === "SUCCESS" ? (
                                    <>
                                        <FunctionField
                                            label="Parent name"
                                            source="parentName"
                                            render={(record) => {
                                                return (
                                                    <Link
                                                        to={`/students/${student?.user?._id}/show`}
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        <li>
                                                            {
                                                                student?.user
                                                                    ?.preferedName
                                                            }
                                                        </li>
                                                    </Link>
                                                );
                                            }}
                                        />
                                    </>
                                ) : null;
                            })}
                    </ol>
                    <Typography variant="h6">
                        Students Attended{" "}
                        {`(${
                            students.length > 0
                                ? students.filter((value) => {
                                      return value?.is_Attended === true;
                                  }).length
                                : 0
                        })`}
                    </Typography>
                    <ol style={{ fontFamily: "sans-serif" }}>
                        {students.length > 0 &&
                            students.map((student) => {
                                return student?.is_Attended === true ? (
                                    <>
                                        <FunctionField
                                            label="Parent name"
                                            source="parentName"
                                            render={(record) => {
                                                return (
                                                    <Link
                                                    to={`/students/${student?.user?._id}/show`}
                                                    style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        <li>
                                                            {
                                                                student?.user
                                                                    ?.preferedName
                                                            }
                                                        </li>
                                                    </Link>
                                                );
                                            }}
                                        />
                                    </>
                                ) : null;
                            })}
                    </ol>
                </SimpleShowLayout>
            </Show>
        </React.Fragment>
    );
}
