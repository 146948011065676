import StudentIcon from "@material-ui/icons/People";
import StudentCreate from "./StudentCreate";
import StudentEdit from "./StudentEdit";
import StudentShow from "./StudentShow";
import StudentsList from "./StudentsList";

const resource = {
    list: StudentsList,
    create: StudentCreate,
    show: StudentShow,
    edit: StudentEdit,
    icon: StudentIcon,
};

export default resource;
