import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { DuplicateClass, UnpublishClass } from "../../../api/ClassActivity";
import _ from "lodash";

const useStyles = makeStyles({
    BanButton: {
        backgroundColor: "#d32f2f",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#d32f2f",
        },
    },

    ActivateButton: {
        backgroundColor: "#43a047",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#43a047",
        },
    },

    OtherButton: {
        backgroundColor: "#2196f3",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#2196f3",
        },
    },
});

const UnpublishClassComponent = ({ record }) => {
    const notify = useNotify();
    const classes = useStyles();

    const [loadingBanButton, setLoadingBanButton] = useState(false);
    const [isClassUnpublished, setIsClassUnpublished] = useState(false);
    const [isClassDuplicated, setIsClassDuplicated] = useState(false);

    const DuplicateClassFunction = async () => {
        setLoadingBanButton(true);
        const data = await DuplicateClass({
            classID: record._id,
        });
        notify(_.get(data, "data.message", "Something went wrong."), {
            type:
                _.get(data, "status") === 200 && _.get(data, "data.success")
                    ? "success"
                    : "error",
        });

        if (_.get(data, "status") === 200 && _.get(data, "data.success")) {
            setIsClassDuplicated(true);
        }

        setLoadingBanButton(false);
    };

    const UnpublishClassFunction = async () => {
        setLoadingBanButton(true);
        const data = await UnpublishClass({
            classID: record._id,
        });
        notify(_.get(data, "data.message", "Something went wrong."), {
            type:
                _.get(data, "status") === 200 && _.get(data, "data.success")
                    ? "success"
                    : "error",
        });

        if (_.get(data, "status") === 200 && _.get(data, "data.success")) {
            setIsClassUnpublished(true);
        }

        setLoadingBanButton(false);
    };

    return (
        <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Class Details</Typography>

            <Box
                sx={{
                    display: "flex",
                    gridGap: "1rem",
                }}
            >
                <Button
                    variant="contained"
                    className={classes.OtherButton}
                    disabled={
                        loadingBanButton ||
                        isClassDuplicated ||
                        _.get(record, "replicated_class_id") ||
                        _.get(record, "is_replicated_class")
                    }
                    onClick={() => {
                        DuplicateClassFunction();
                    }}
                    disableElevation
                >
                    Duplicate
                </Button>

                <Button
                    variant="contained"
                    className={classes.OtherButton}
                    disabled={loadingBanButton || isClassUnpublished}
                    onClick={() => {
                        UnpublishClassFunction();
                    }}
                    disableElevation
                >
                    Unpublish
                </Button>
            </Box>
        </Box>
    );
};

export default UnpublishClassComponent;
