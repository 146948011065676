import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import WithAxios from "./utils/WithAxios";

ReactDOM.render(
    <WithAxios>
        <App />
    </WithAxios>,
    document.getElementById("root")
);
