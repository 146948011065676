import { loginUser } from "./api/login";

const authProvider = {
    login: async ({ email, password }) => {
        const data = await loginUser(email, password)
            .then((res) => {
                const resData = res.data;
                if (!resData?.success) {
                    return Promise.reject();
                } else {
                    localStorage.setItem("auth", JSON.stringify(resData));
                    localStorage.setItem(
                        "username",
                        JSON.stringify(resData?.data?.user?.email)
                    );
                    return Promise.resolve(resData);
                }
            })
            .then((auth) => {
                return Promise.resolve(auth);
            })
            .catch((error) => {
                return Promise.reject();
            });
        return data;
    },
    logout: () => {
        localStorage.clear();
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem("username") ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject("Unknown method"),
    getIdentity: () =>
        Promise.resolve({
            id: localStorage.getItem("auth")?.data?.user?.id,
            fullName: JSON.parse(localStorage.getItem("username")),
        }),
};

export default authProvider;
