import { Box, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { required, TextInput } from "react-admin";

const PersonalDetails = () => {
    return (
        <Fragment>
            <Typography variant="h6" gutterBottom>
                Store Profile
            </Typography>

            <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="state"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="country"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

const requiredValidate = [required()];

export default PersonalDetails;
