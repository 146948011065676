import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import {
    Datagrid,
    DateField,
    ExportButton,
    FunctionField,
    List,
    NumberField,
    TextField,
    TopToolbar,
} from "react-admin";
import { MinutesToHours } from "../../../../utils/MinutesToHours";
import Aside from "./Aside";
import ClassTags from "./ClassTags";
import MobileGrid from "./Mobile/MobileGrid";

const useStyles = makeStyles((theme) => ({
    hiddenOnSmallScreens: {
        display: "table-cell",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

const ListActions = (props) => {
    return (
        <TopToolbar>
            <ExportButton maxResults={Number.MAX_SAFE_INTEGER} />
        </TopToolbar>
    );
};

const PostBulkActionButtons = (props) => <Fragment></Fragment>;

const StudentsList = (props) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    return (
        <List
            {...props}
            sort={{
                field: "createdAt",
                order: "DESC",
            }}
            perPage={10}
            aside={<Aside />}
            actions={<ListActions />}
            bulkActionButtons={<PostBulkActionButtons />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid optimized rowClick="show">
                    <TextField label="User" source="preferedName" />
                    <TextField
                        label="Student"
                        source="students[0].studentpreferedname"
                    />
                    <NumberField label="Age" source="students[0].age" />
                    <NumberField label="Student Age" source="age" />
                    <TextField label="Email" source="email" />
                    <DateField label="Registration Date" source="createdAt" />
                    <DateField
                        label="Last Logged in Date"
                        source="lastLoggedInDate"
                        showTime
                    />
                    <FunctionField
                        label="Location"
                        source="country"
                        render={(record) => {
                            return record.state && record.country
                                ? `${record.state}, ${record.country}`
                                : "-";
                        }}
                    />

                    <NumberField
                        label="Classes attended"
                        source="totalAttendedSessions"
                    />
                    <FunctionField
                        label="Refunds"
                        source="noOfRefunds"
                        render={(record) => {
                            return record.noOfRefunds &&
                                record.totalAmountRefunded
                                ? `${record.noOfRefunds} times, €${
                                      Math.round(
                                          (record.totalAmountRefunded +
                                              Number.EPSILON) *
                                              100
                                      ) / 100
                                  }`
                                : "-";
                        }}
                    />
                    <FunctionField
                        label="Average start time of class"
                        source="avgClassStartTime"
                        render={(record) => {
                            return record.avgClassStartTime
                                ? `${record.avgClassStartTime} GMT`
                                : "-";
                        }}
                    />
                    <FunctionField
                        label="Average duration of class"
                        source="avgClassDuration"
                        render={(record) => {
                            return record.avgClassDuration
                                ? MinutesToHours(
                                      Math.round(
                                          (record.avgClassDuration +
                                              Number.EPSILON) *
                                              100
                                      ) / 100
                                  )
                                : "-";
                        }}
                    />
                    <ClassTags
                        cellClassName={classes.hiddenOnSmallScreens}
                        headerClassName={classes.hiddenOnSmallScreens}
                        label="Tags of top 3 classes registered"
                    />

                    <FunctionField
                        label="Total amount spent"
                        source="totalSpent"
                        render={(record) => {
                            return record.totalAmountSpent
                                ? `€${
                                      Math.round(
                                          (record.totalAmountSpent +
                                              Number.EPSILON) *
                                              100
                                      ) / 100
                                  }`
                                : "-";
                        }}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default StudentsList;
