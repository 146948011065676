// in src/comments.js
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { DateField, EditButton, FunctionField } from "react-admin";
import AvatarField from "../../../../../components/AvatarField";

const useStyles = makeStyles((theme) => ({
    root: { margin: "1em" },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "0.5rem 0",
    },
    cardTitleContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    cardContent: {
        ...theme.typography.body1,
        display: "flex",
        flexDirection: "column",
    },
}));

const MobileGrid = ({ ids, data, basePath }) => {
    const classes = useStyles();

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map((id) => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{`${data[id].preferedName}`}</h2>
                                <EditButton
                                    resource="visitors"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        avatar={<AvatarField record={data[id]} size="45" />}
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            Location&nbsp;:&nbsp;
                            <FunctionField
                                label="Location"
                                record={data[id]}
                                render={(record) => {
                                    return record.state && record.country
                                        ? `${record.state} ${record.country}`
                                        : "-";
                                }}
                            />
                        </div>
                        <div>
                            Created at&nbsp;:&nbsp;
                            <DateField
                                record={data[id]}
                                source="createdAt"
                                showTime
                            />
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
