import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    ExportButton,
    FunctionField,
    List,
    NumberField,
    TextField,
    TopToolbar,
} from "react-admin";
import CustomerLinkField from "../../../../components/CustomerLinkField";
import { MinutesToHours } from "../../../../utils/MinutesToHours";
import Aside from "./Aside";
import ClassTags from "./ClassTags";
import MobileGrid from "./Mobile/MobileGrid";

const useStyles = makeStyles((theme) => ({
    hiddenOnSmallScreens: {
        display: "table-cell",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

const ListActions = (props) => {
    return (
        <TopToolbar>
            <ExportButton maxResults={Number.MAX_SAFE_INTEGER} />
        </TopToolbar>
    );
};

const PostBulkActionButtons = (props) => <Fragment></Fragment>;

const TeachersList = (props) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    return (
        <List
            {...props}
            sort={{
                field: "createdAt",
                order: "DESC",
            }}
            perPage={10}
            aside={<Aside />}
            actions={<ListActions />}
            bulkActionButtons={<PostBulkActionButtons />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid optimized rowClick="show">
                    <TextField label="User" source="name" />
                    <TextField label="Email" source="email" />
                    <DateField label="Registration Date" source="createdAt" />
                    <DateField
                        label="Last Logged in Date"
                        source="lastLoggedInDate"
                        showTime
                    />
                    <FunctionField
                        label="Location"
                        source="country"
                        render={(record) => {
                            return record.state && record.country
                                ? `${record.state}, ${record.country}`
                                : "-";
                        }}
                    />
                    <BooleanField
                        label="Is stripe account activated"
                        source="isStripeAccountSubmitted"
                    />

                    <NumberField label="Age" source="age" />
                    <NumberField
                        label="No. of classes published"
                        source="noOfClassPublished"
                    />
                    <NumberField
                        label="No. of classes conducted"
                        source="totalConductedSessions"
                    />
                    <NumberField
                        label="Average no. of students per class conducted"
                        source="avgNoStudentPerClass"
                    />
                    <FunctionField
                        label="Average start time of class"
                        source="avgClassStartTime"
                        render={(record) => {
                            return record.avgClassStartTime
                                ? `${record.avgClassStartTime} GMT`
                                : "-";
                        }}
                    />
                    <FunctionField
                        label="Average duration of class"
                        source="avgClassDuration"
                        render={(record) => {
                            return record.avgClassDuration
                                ? MinutesToHours(
                                      Math.round(
                                          (record.avgClassDuration +
                                              Number.EPSILON) *
                                              100
                                      ) / 100
                                  )
                                : "-";
                        }}
                    />
                    <ClassTags
                        cellClassName={classes.hiddenOnSmallScreens}
                        headerClassName={classes.hiddenOnSmallScreens}
                        label="Tags of top 3 classes registered"
                    />

                    <FunctionField
                        label="Total amount earned"
                        source="totalAmountEarned"
                        render={(record) => {
                            return record.totalAmountEarned
                                ? `€${record.totalAmountEarned}`
                                : "-";
                        }}
                    />
                    <FunctionField
                        label="Total refunds"
                        source="totalAmountRefunded"
                        render={(record) => {
                            return record.totalAmountRefunded
                                ? `€${record.totalAmountRefunded}`
                                : "-";
                        }}
                    />
                    <NumberField
                        label="No. of times they have issued a refund"
                        source="noOfRefunds"
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default TeachersList;
