import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { TypographyStyle } from ".";
import { MinutesToHours } from "../../../../utils/MinutesToHours";

const useStyles = makeStyles({
    rightAlignedCell: {
        textAlign: "right",
    },

    transactionOut: {
        color: "rgb(227, 107, 45)",
    },

    transactionIn: {
        color: "rgb(0, 43, 74)",
    },
});

const TransactionHistory = ({ record }) => {
    const classes = useStyles();
    const [transactionHistory, setTransactionHistory] = useState([]);
    console.log(record, " <<< record");

    useEffect(() => {
        if (record.transactionHistory && record.transactionHistory.length)
            // eslint-disable-next-line
            record.transactionHistory.map((transaction) => {
                if (transaction.students && transaction.students.length)
                    // eslint-disable-next-line
                    transaction.students.map((student) => {
                        setTransactionHistory((transactionHistory) => {
                            return [
                                ...transactionHistory,
                                {
                                    date:
                                        student.payment_status ===
                                        "refund_succeeded"
                                            ? _.get(student, "refund.date")
                                            : _.get(
                                                  transaction,
                                                  "session_start_time"
                                              ),
                                    className: _.get(
                                        transaction,
                                        "class_name",
                                        "-"
                                    ),
                                    duration: _.get(
                                        transaction,
                                        "session_duration",
                                        "-"
                                    ),
                                    amount:
                                        student.payment_status ===
                                        "refund_succeeded"
                                            ? _.get(
                                                  student,
                                                  "refund.amount",
                                                  "-"
                                              )
                                            : _.get(student, "amount", "-"),
                                    paymentStatus: _.get(
                                        student,
                                        "payment_status"
                                    ),
                                },
                            ];
                        });
                    });
            });
    }, [record.transactionHistory]);

    const getDate = (time) => {
        if (time) {
            return moment.utc(time).format("DD MMM YYYY");
        } else {
            return "-";
        }
    };

    const getTime = (time) => {
        if (time) {
            return moment.utc(time).format("hh:mm A");
        } else {
            return "-";
        }
    };

    return record ? (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>

                    <TableCell>Name of Class</TableCell>

                    <TableCell>Time (Duration)</TableCell>

                    <TableCell className={classes.rightAlignedCell}>
                        Transacted (£) in/
                        <span className={classes.transactionOut}>out</span>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {transactionHistory && transactionHistory.length
                    ? transactionHistory.map((transaction) => {
                          return (
                              <TableRow key={transaction._id}>
                                  <TableCell>
                                      {getDate(_.get(transaction, "date"))}
                                  </TableCell>

                                  <TableCell>
                                      {_.get(transaction, "className", "-")}
                                  </TableCell>

                                  <TableCell>
                                      {`${getTime(
                                          _.get(transaction, "date")
                                      )} GMT`}
                                      <br />
                                      {`(${MinutesToHours(
                                          _.get(transaction, "duration", "-")
                                      )})`}
                                  </TableCell>

                                  <TableCell
                                      className={`${classes.rightAlignedCell} ${
                                          transaction.paymentStatus ===
                                          "succeeded"
                                              ? classes.transactionIn
                                              : transaction.paymentStatus ===
                                                "refund_succeeded"
                                              ? classes.transactionOut
                                              : null
                                      }`}
                                  >
                                      {transaction.paymentStatus === "canceled"
                                          ? "Failed transaction"
                                          : _.get(transaction, "amount")
                                          ? Math.round(
                                                (transaction.amount +
                                                    Number.EPSILON) *
                                                    100
                                            ) / 100
                                          : ""}
                                  </TableCell>
                              </TableRow>
                          );
                      })
                    : null}
            </TableBody>
        </Table>
    ) : null;
};

export default TransactionHistory;
