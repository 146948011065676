import { Box, Card, CardContent } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import React from "react";
import { Edit, SaveButton, SimpleForm, Toolbar } from "react-admin";
import FullNameField from "../../../../components/FullNameField";
import { ValidatePasswords } from "../../../../utils/ValidatePasswords";
import PersonalDetails from "./PersonalDetails";

const saveForm = (values) => {
    console.log(values, " <<<< SUBMIT");
};

const banUser = (values) => {
    console.log(values, " <<<< BAN");
};

const VisitorTitle = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const EditToolbar = (props) => {
    const toolbarStyles = {
        display: "flex",
        gap: "3rem",
    };

    const saveButton = {
        backgroundColor: "#61b051",
    };

    const banButton = {
        backgroundColor: "#e74432",
    };

    return (
        <Toolbar style={toolbarStyles} {...props}>
            <SaveButton label="Save" onSave={saveForm} style={saveButton} />

            <SaveButton
                label="Ban"
                onSave={banUser}
                icon={<BlockIcon />}
                style={banButton}
            />
        </Toolbar>
    );
};

const StudentEdit = (props) => {
    return (
        <Edit title={<VisitorTitle />} component="div" {...props}>
            <SimpleForm
                {...props}
                validate={ValidatePasswords}
                toolbar={<EditToolbar />}
            >
                <Card
                    style={{
                        width: "100%",
                    }}
                >
                    <CardContent>
                        <Box display={{ md: "block", lg: "flex" }}>
                            <Box flex={3} mr={{ md: 0, lg: "1em" }}>
                                <PersonalDetails />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </SimpleForm>
        </Edit>
    );
};

export default StudentEdit;
