import { useNotify } from "react-admin";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
    HandleTransactionHistory,
    ResetPassword,
    ReVerifyUser,
    ToggleUserStatus,
} from "../../../../api/RestrictUser";
import _ from "lodash";

const useStyles = makeStyles({
    BanButton: {
        backgroundColor: "#d32f2f",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#d32f2f",
        },
    },

    ActivateButton: {
        backgroundColor: "#43a047",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#43a047",
        },
    },

    OtherButton: {
        backgroundColor: "#2196f3",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#2196f3",
        },
    },
});

const BanUser = ({ record }) => {
    const notify = useNotify();
    const classes = useStyles();
    const [loadingBanButton, setLoadingBanButton] = useState(false);
    const [userStatus, setUserStatus] = useState("");
    const [transactionHistory, setTransactionHistory] = useState("");

    useEffect(() => {
        setUserStatus(record.accountStatus);
        setTransactionHistory(record.hideTransactionHistory);
    }, [record]);

    const ToggleUserStatusFunction = async (accountStatus = "ACTIVE") => {
        setLoadingBanButton(true);
        const data = await ToggleUserStatus({
            accountStatus: userStatus === "SUSPENDED" ? "ACTIVE" : "SUSPENDED",
            id: record._id,
        });
        notify(_.get(data, "data.title", "Something went wrong."), {
            type:
                _.get(data, "status") === 200 && _.get(data, "data.success")
                    ? "success"
                    : "error",
        });

        if (_.get(data, "status") === 200 && _.get(data, "data.success")) {
            setUserStatus(userStatus === "SUSPENDED" ? "ACTIVE" : "SUSPENDED");
        }
        setLoadingBanButton(false);
    };

    const ToggleTransactionHistory = async (hideTransactionHistory = false) => {
        setLoadingBanButton(true);
        const data = await HandleTransactionHistory({
            hideTransactionHistory: transactionHistory === true ? false : true,
            id: record._id,
        });
        notify(_.get(data, "data.message", "Something went wrong."), {
            type:
                _.get(data, "status") === 200 && _.get(data, "data.success")
                    ? "success"
                    : "error",
        });

        if (_.get(data, "status") === 200 && _.get(data, "data.success")) {
            setTransactionHistory(transactionHistory === true ? false : true);
        }
        setLoadingBanButton(false);
    };

    const ResetPasswordFunction = async () => {
        setLoadingBanButton(true);
        const body = {
            email: _.get(record, "email"),
        };
        const data = await ResetPassword(body);
        notify(_.get(data, "data.title", "Something went wrong."), {
            type:
                _.get(data, "status") === 200 && _.get(data, "data.success")
                    ? "success"
                    : "error",
        });

        setLoadingBanButton(false);
    };

    const ReVerifyUserFunction = async () => {
        setLoadingBanButton(true);
        const body = {
            email: _.get(record, "email"),
        };
        const data = await ReVerifyUser(body);
        notify(_.get(data, "data.title", "Something went wrong."), {
            type:
                _.get(data, "status") === 200 && _.get(data, "data.success")
                    ? "success"
                    : "error",
        });

        setLoadingBanButton(false);
    };

    return (
        <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Personal Details</Typography>

            <Box
                sx={{
                    display: "flex",
                    gridGap: "1rem",
                }}
            >
                <Button
                    variant="contained"
                    className={
                        userStatus === "SUSPENDED"
                            ? classes.ActivateButton
                            : classes.BanButton
                    }
                    disabled={loadingBanButton}
                    onClick={() => {
                        ToggleUserStatusFunction();
                    }}
                    disableElevation
                >
                    {userStatus === "SUSPENDED" ? "Activate" : "Ban"} User
                </Button>

                <Button
                    variant="contained"
                    className={
                        transactionHistory === true
                            ? classes.ActivateButton
                            : classes.BanButton
                    }
                    disabled={loadingBanButton}
                    onClick={() => {
                        ToggleTransactionHistory();
                    }}
                    disableElevation
                >
                    {transactionHistory === true ? "Show" : "Hide"} Transaction History
                </Button>

                <Button
                    variant="contained"
                    className={classes.OtherButton}
                    disabled={loadingBanButton}
                    onClick={() => {
                        ResetPasswordFunction();
                    }}
                    disableElevation
                >
                    Change Password
                </Button>

                <Button
                    variant="contained"
                    className={classes.OtherButton}
                    disabled={loadingBanButton}
                    onClick={() => {
                        ReVerifyUserFunction();
                    }}
                    disableElevation
                >
                    Reverify User
                </Button>
            </Box>
        </Box>
    );
};

export default BanUser;
