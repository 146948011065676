import Carousel from "nuka-carousel";
import React from "react";

const ProfilePhotoCarousel = ({ profilePhotos }) => {
    const slides = profilePhotos.map((photo, index) =>
        photo.type === "VIDEO" ? (
            <video
                src={photo.location}
                controls
                alt={`Slide ${index + 1}`}
                key={index}
                style={{
                    height: 392,
                    borderRadius: "10px",
                    width: "auto",
                    margin: "auto",
                    display: "flex",
                }}
            />
        ) : (
            <img
                src={photo.location}
                alt={`Slide ${index + 1}`}
                key={index}
                style={{
                    height: 392,
                    borderRadius: "10px",
                    width: "auto",
                    margin: "auto",
                    display: "flex",
                }}
            />
        )
    );

    return (
        <div
            style={{
                width: "50vw",
                margin: "auto",
                marginTop: 20,
            }}
        >
            <Carousel wrapAround={true}>{slides}</Carousel>
        </div>
    );
};

export default ProfilePhotoCarousel;
