import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { Notification, useLogin, useNotify, useTranslate } from "react-admin";
import { Field, withTypes } from "react-final-form";
import { useLocation, useHistory } from "react-router-dom";
// import { lightTheme } from "../../shared/themes";
import { lightTheme } from "../Layout/themes";

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "url(https://source.unsplash.com/random/1600x900)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    card: {
        minWidth: 300,
        marginTop: "6em",
    },
    avatar: {
        margin: "1em",
        display: "flex",
        justifyContent: "center",
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: "1em",
        display: "flex",
        justifyContent: "center",
        color: theme.palette.grey[500],
    },
    form: {
        padding: "0 1em 1em 1em",
    },
    input: {
        marginTop: "1em",
    },
    actions: {
        padding: "0 1em 1em 1em",
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const { Form } = withTypes();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const history = useHistory();

    const handleSubmit = (auth) => {
        setLoading(false);
        login(auth, location.state ? location.state.nextPathname : "/")
            .then((response) => {
                history.push("/");
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === "string"
                        ? error
                        : typeof error === "undefined" || !error.message
                        ? "ra.auth.sign_in_error"
                        : error.message,
                    "warning",
                    {
                        _:
                            typeof error === "string"
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    }
                );
            });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            {/* <div className={classes.hint}>
                                Hint: demo / demo
                            </div> */}
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="email"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={"Email"}
                                        disabled={loading}
                                        autoComplete="email"
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={"Password"}
                                        type="password"
                                        disabled={loading}
                                        autoComplete="current-password"
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate("ra.auth.sign_in")}
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
