import { Drawer, useMediaQuery } from "@material-ui/core";
import React, { useCallback } from "react";
import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    Link,
} from "react-admin";
import StarRatingField from "./StarRatingField";
import ReviewEdit from "./ReviewEdit";
import { useHistory, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import reviewFilters from "./reviewFilters";
import rowStyle from "./rowStyle";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(["all"], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
    headerRow: {
        borderLeftColor: "transparent",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
    },
    headerCell: {
        padding: "6px 8px 6px 8px",
        fontWeight: "bolder",
    },
    rowCell: {
        padding: "6px 8px 6px 8px",
    },
    comment: {
        maxWidth: "18em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

const ReviewsBulkActionButtons = (props) => (
    <React.Fragment>
        {/* <BulkAcceptButton {...props} />
        <BulkRejectButton {...props} />
        <BulkDeleteButton {...props} /> */}
    </React.Fragment>
);

const ReviewList = (props) => {
    const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    const classes = useStyles();

    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push("/reviews");
    }, [history]);

    return (
        <div className={classes.root}>
            <Route path="/reviews/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match?.params &&
                        match.params.id
                    );
                    return (
                        <React.Fragment>
                            <List
                                {...props}
                                className={`${classes.list} ${
                                    isMatch ? classes.listWithDrawer : null
                                }`}
                                sort={{
                                    field: "createdAt",
                                    order: "DESC",
                                }}
                                perPage={10}
                                // aside={<Aside />}
                                bulkActionButtons={<ReviewsBulkActionButtons />}
                                filters={reviewFilters}
                            >
                                {isXsmall ? null : (
                                    <Datagrid
                                        optimized
                                        rowClick="edit"
                                        classes={{
                                            headerRow: classes.headerRow,
                                            headerCell: classes.headerCell,
                                            rowCell: classes.rowCell,
                                        }}
                                        rowStyle={rowStyle(
                                            match
                                                ? match?.params?.id
                                                : undefined
                                        )}
                                    >
                                        {/* <SearchInput source="parentName" /> */}
                                        {/* <TextField source="role" /> */}
                                        <DateField
                                            source="createdAt"
                                            label="Date"
                                        />
                                            <FunctionField
                                                label="Parent Name"
                                                source="parentName"
                                                render={(record) => {
                                                    return record.parentName
                                                        ? record.parentName
                                                        : "-";
                                                }}
                                            />
                                        <TextField
                                            source="className"
                                            label="Class Name"
                                        />
                                        <StarRatingField
                                            size="small"
                                            type="class_rate"
                                            label="Class Ratings"
                                            source="classRate"
                                        />
                                        <StarRatingField
                                            size="small"
                                            type="teacher_rate"
                                            label="Teacher Ratings"
                                            source="teacherRate"
                                        />
                                        {/* <TextField source="review" /> */}
                                        <FunctionField
                                            label="Review"
                                            source="review"
                                            render={(record) => {
                                                return record.review
                                                    ? `${record.review}`
                                                    : "-";
                                            }}
                                            cellClassName={classes.comment}
                                        />
                                        {}
                                        <FunctionField
                                            label="Review Reported"
                                            source="reported_req_completed_by_admin"
                                            render={(record) => {
                                                return !record?.is_reported
                                                    ? "-"
                                                    : record?.reported_req_completed_by_admin ===
                                                      true
                                                    ? `Resolved (${
                                                          record.deleted_by_admin
                                                              ? "Rejected"
                                                              : "Accepted"
                                                      })`
                                                    : "Pending";
                                            }}
                                        />
                                    </Datagrid>
                                )}
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <ReviewEdit
                                        id={match?.params?.id}
                                        onCancel={handleClose}
                                        resource={props.resource}
                                        basePath={props.basePath}
                                    />
                                ) : null}
                            </Drawer>
                        </React.Fragment>
                    );
                }}
            </Route>
        </div>
    );
};

export default ReviewList;
