import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { TypographyStyle } from "./index";

const useStyles = makeStyles({
    rightAlignedCell: {
        textAlign: "right",
    },

    transactionOut: {
        color: "rgb(227, 107, 45)",
    },

    transactionIn: {
        color: "rgb(0, 43, 74)",
    },

    starIcon: {
        color: "#fcb648",
        fontSize: "20px",
    },
});

const ClassReviews = (props) => {
    const classes = useStyles();
    const { record } = props;

    const getDate = (time) => {
        if (time) {
            return moment.utc(time).format("DD MMM YYYY");
        } else {
            return "-";
        }
    };

    const ratingStar = (review) => {
        const ratingArr = [];

        for (let i = 0; i < parseInt(review); i++) {
            ratingArr.push(
                <FontAwesomeIcon icon={faStar} className={classes.starIcon} />
            );
        }

        return ratingArr;
    };

    return (
        <Fragment>
            <Typography variant="h6" style={TypographyStyle}>
                Class Reviews List
            </Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>

                        <TableCell>Name of Class</TableCell>

                        <TableCell className={classes.rightAlignedCell}>
                            Rating of Class
                        </TableCell>

                        <TableCell className={classes.rightAlignedCell}>
                            Rating of Teacher
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.classReviews && record.classReviews.length
                        ? record.classReviews.map((review) => {
                              return (
                                  <TableRow key={review._id}>
                                      <TableCell>
                                          {getDate(_.get(review, "updatedAt"))}
                                      </TableCell>

                                      <TableCell>
                                          {_.get(
                                              review,
                                              "session_history_id.class_name",
                                              "-"
                                          )}
                                      </TableCell>

                                      <TableCell
                                          className={classes.rightAlignedCell}
                                      >
                                          {ratingStar(
                                              _.defaultTo(
                                                  _.get(review, "class_rate"),
                                                  0
                                              )
                                          )}
                                      </TableCell>

                                      <TableCell
                                          className={classes.rightAlignedCell}
                                      >
                                          {ratingStar(
                                              _.defaultTo(
                                                  _.get(review, "teacher_rate"),
                                                  0
                                              )
                                          )}
                                      </TableCell>
                                  </TableRow>
                              );
                          })
                        : null}
                </TableBody>
            </Table>
        </Fragment>
    );
};

export default ClassReviews;
