import React from "react";
import { Layout as RALayout } from "react-admin";
import { useSelector } from "react-redux";
import Menu from "./Menu";
import { darkTheme, lightTheme } from "./themes";

const Layout = (props) => {
    const theme = useSelector((state) =>
        state.theme === "dark" ? darkTheme : lightTheme
    );
    return <RALayout {...props} menu={Menu} theme={theme} />;
};

export default Layout;
