import React from "react";
import {
    NumberField,
    List,
    Datagrid,
    DateField,
    TextField,
    FunctionField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ClassTags from "./../ClassList/ClassTags";
import ClassFilters from "./../ClassList/ClassFilters";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(["all"], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
    headerRow: {
        borderLeftColor: "transparent",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
    },
    headerCell: {
        padding: "6px 8px 6px 8px",
        fontWeight: "bolder",
    },
    rowCell: {
        padding: "6px 8px 6px 8px",
    },
    comment: {
        maxWidth: "18em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

export const UpcomingClassesList = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <List
                {...props}
                perPage={10}
                className={`${classes.list}`}
                filter={ClassFilters}
                sort={{
                    field: "configuration.session_start_time",
                    order: "DESC",
                }}
            >
                <Datagrid
                    {...props}
                    optimized
                    classes={{
                        headerRow: classes.headerRow,
                        headerCell: classes.headerCell,
                        rowCell: classes.rowCell,
                    }}
                    rowClick="show"
                >
                    <DateField
                        source="configuration.session_start_time"
                        label="Date and time"
                        showTime
                    />
                    <TextField source="class_name" label="Class Name" />
                    {/* <ReferenceField reference="teachers" source="teacherName" label="Account name" >
                        <TextField source="teacherName" label="Account name" />
                    </ReferenceField> */}
                    <TextField source="teacherName" label="Account name" />
                    <FunctionField
                        label="Age group (years)"
                        source="age_group"
                        render={(record) => {
                            return record?.configuration?.age_group;
                        }}
                    />
                    <FunctionField
                        label="Size of class"
                        source="max_students"
                        render={(record) => {
                            return record?.configuration?.max_students;
                        }}
                    />
                    <NumberField
                        label="Total Students Enrolled"
                        source="studentsRegisterLength"
                    />
                    <FunctionField
                        label="Duration (mins)"
                        source="session_duration"
                        render={(record) => {
                            return record?.configuration?.session_duration;
                        }}
                    />
                    <ClassTags
                        source="learning_objectives"
                        label="Class Tags"
                    />
                </Datagrid>
            </List>
        </div>
    );
};

export default UpcomingClassesList;
