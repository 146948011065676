import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { MinutesToHours } from "../../../../utils/MinutesToHours";
import { TypographyStyle } from "./index";

const useStyles = makeStyles({
    rightAlignedCell: {
        textAlign: "right",
    },

    transactionOut: {
        color: "rgb(227, 107, 45)",
    },

    transactionIn: {
        color: "rgb(0, 43, 74)",
    },
});

const StudentDashboard = ({ record }) => {
    const classes = useStyles();

    const getDate = (time) => {
        if (time) {
            return moment.utc(time).format("DD MMM YYYY");
        } else {
            return "-";
        }
    };

    const getTime = (time) => {
        if (time) {
            return moment.utc(time).format("hh:mm A");
        } else {
            return "-";
        }
    };

    return record ? (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>

                    <TableCell>Name of Class</TableCell>

                    <TableCell className={classes.rightAlignedCell}>
                        Time (Duration)
                    </TableCell>

                    <TableCell className={classes.rightAlignedCell}>
                        Students registered
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.dashboard && record.dashboard.length
                    ? record.dashboard.map((dashboard) => {
                          return (
                              <TableRow key={dashboard._id}>
                                  <TableCell>
                                      {getDate(
                                          _.get(
                                              dashboard,
                                              "configuration.session_start_time"
                                          )
                                      )}
                                  </TableCell>

                                  <TableCell>
                                      {_.get(dashboard, "class_name", "-")}
                                  </TableCell>

                                  <TableCell
                                      className={classes.rightAlignedCell}
                                  >
                                      {`${getTime(
                                          _.get(
                                              dashboard,
                                              "configuration.session_start_time"
                                          )
                                      )} GMT`}
                                      <br />
                                      {`(${MinutesToHours(
                                          _.get(
                                              dashboard,
                                              "configuration.session_duration",
                                              "-"
                                          )
                                      )})`}
                                  </TableCell>

                                  <TableCell
                                      className={classes.rightAlignedCell}
                                  >
                                      {`${_.size(
                                          _.get(dashboard, "students_ids", [])
                                      )} of ${_.get(
                                          dashboard,
                                          "configuration.max_students",
                                          "-"
                                      )}`}
                                  </TableCell>
                              </TableRow>
                          );
                      })
                    : null}
            </TableBody>
        </Table>
    ) : null;
};

export default StudentDashboard;
