import TeacherIcon from "@material-ui/icons/People";
import TeacherCreate from "./TeacherCreate";
import TeacherEdit from "./TeacherEdit";
import TeacherShow from "./TeacherShow";
import TeachersList from "./TeachersList";

const resource = {
    list: TeachersList,
    create: TeacherCreate,
    show: TeacherShow,
    edit: TeacherEdit,
    icon: TeacherIcon,
};

export default resource;
