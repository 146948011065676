import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useState } from "react";
import { DashboardMenuItem, MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import students from "../Users/Students";
import teachers from "../Users/Teachers";
import reviews from "../Reviews";
import SubMenu from "./SubMenu";
import classList from "./../ClassList";
// import ClassList from './../Classes/ClassList';

const Menu = ({ dense = false }) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change
    const classes = useStyles();

    const handleToggle = (menu) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div
            className={classnames(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle("menuSales")}
                isOpen={state.menuSales}
                name="Users"
                icon={<students.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={{
                        pathname: "/students",
                        state: { _scrollToTop: true },
                    }}
                    primaryText={"Students"}
                    leftIcon={<students.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: "/teachers",
                        state: { _scrollToTop: true },
                    }}
                    primaryText={"Teachers"}
                    leftIcon={<teachers.icon />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to={{
                    pathname: "/reviews",
                    state: { _scrollToTop: true },
                }}
                primaryText={"Reviews"}
                leftIcon={<reviews.icon />}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: "/classes",
                    state: { _scrollToTop: true },
                }}
                primaryText={"Class List"}
                leftIcon={<classList.icon />}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: "/upcomingClasses",
                    state: { _scrollToTop: true },
                }}
                primaryText={"Upcoming Classes"}
                leftIcon={<classList.icon />}
                dense={dense}
            />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));

export default Menu;
