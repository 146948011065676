import { Box, Typography } from "@material-ui/core";
import React from "react";

const IDPicture = () => {
    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                ID Picture
            </Typography>

            <Typography variant="body2" gutterBottom>
                ID Picture
            </Typography>
        </Box>
    );
};

export default IDPicture;
