import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
    main: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const ClassTags = ({ record }) => {
    const classes = useStyles();

    return record ? (
        <span className={classes.main}>
            {record.tags && record.tags.length
                ? record.tags.map((segment) => {
                      return (
                          <Chip
                              size="small"
                              key={segment}
                              className={classes.chip}
                              label={segment}
                          />
                      );
                  })
                : "-"}
        </span>
    ) : null;
};

export default ClassTags;
