import { request } from "../shared/RequestWrapper";

export const UnpublishClass = async ({ classID }) => {
    const data = await request({
        url: `/unpublishClass/${classID}`,
        method: "POST",
    });

    return data;
};

export const DuplicateClass = async ({ classID }) => {
    const data = await request({
        url: `/classReplica/${classID}`,
        method: "POST",
    });

    return data;
};
