import { Box, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import {
    DateInput,
    email,
    NumberInput,
    required,
    TextInput,
} from "react-admin";

const PersonalDetails = () => {
    return (
        <Fragment>
            <Typography variant="h6" gutterBottom>
                Personal Details
            </Typography>

            <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        type="email"
                        source="email"
                        resource="users"
                        validate={[email(), required()]}
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <DateInput
                        source="birthDate"
                        label="Birthday"
                        resource="users"
                        fullWidth
                        helperText={false}
                        validate={requiredValidate}
                    />
                </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="name"
                        label="Parent Name"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="preferedName"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="studentname"
                        label="Student Name"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="studentpreferedname"
                        label="Student Preferred Name"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <DateInput
                        source="studentbirthdate"
                        label="Student Birthday"
                        resource="users"
                        fullWidth
                        helperText={false}
                        validate={requiredValidate}
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                        source="contact"
                        label="Emergency Contact"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="state"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                        source="country"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

const requiredValidate = [required()];

export default PersonalDetails;
