import { request } from "../shared/RequestWrapper";

export const loginUser = async (email, password) => {
    const data = await request({
        url: "/login",
        method: "POST",
        data: {email: email, password: password},
    });
    return data;
};
