import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/icons/Stars";
import React from "react";

const useStyles = makeStyles({
    root: {
        opacity: 0.87,
        whiteSpace: "nowrap",
        display: "flex",
    },
    large: {
        width: 20,
        height: 20,
    },
    small: {
        width: 15,
        height: 15,
    },
});
let typeSource = "classRate"
const StarRatingField = ({ record, size = "large", type }) => {
    const classes = useStyles();
    typeSource = type;
    return record ? (
        <span className={classes.root}>
            {Array(record[type])
                .fill(true)
                .map((_, i) => (
                    <Icon
                        key={i}
                        className={
                            size === "large" ? classes.large : classes.small
                        }
                    />
                ))}
        </span>
    ) : null;
};

StarRatingField.defaultProps = {
    label: "resources.reviews.fields.rating",
    source: typeSource,
    addLabel: true,
};

export default StarRatingField;
