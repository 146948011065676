import { Card as MuiCard, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { FilterLiveSearch } from "react-admin";

const Card = withStyles((theme) => ({
    root: {
        [theme.breakpoints.up("sm")]: {
            order: -1,
            minWidth: "18.4rem",
            marginRight: "1em",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}))(MuiCard);

const Aside = () => (
    <Card>
        <CardContent>
            <FilterLiveSearch />
        </CardContent>
    </Card>
);

export default Aside;
