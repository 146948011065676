import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Edit, FormWithRedirect, PasswordInput, Toolbar } from "react-admin";
import FullNameField from "../../../../components/FullNameField";
import { ValidatePasswords } from "../../../../utils/ValidatePasswords";
import IDPicture from "./IDPicture";
import PersonalDetails from "./PersonalDetails";
import StoreProfile from "./StoreProfile";

const TeacherEdit = (props) => {
    return (
        <Edit title={<VisitorTitle />} component="div" {...props}>
            <VisitorForm />
        </Edit>
    );
};

const VisitorTitle = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const VisitorForm = (props) => {
    return (
        <FormWithRedirect
            {...props}
            validate={ValidatePasswords}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: "block", lg: "flex" }}>
                                <Box flex={3} mr={{ md: 0, lg: "1em" }}>
                                    <PersonalDetails />
                                    <IDPicture />

                                    <Box mt="1em" />

                                    <StoreProfile />

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        Password
                                    </Typography>
                                    <Box display={{ xs: "block", sm: "flex" }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: "0.5em" }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: "0.5em" }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="users"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default TeacherEdit;
