import { Backdrop, Box, Fade, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import React from "react";
import ProfilePhotoCarousel from "./ProfilePhotoCarousel";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10,
        "&:focus-visible": {
            outline: "none",
        },
    },
}));

const IDPicture = ({
    record,
    isImageModalOpen,
    setIsImageModalOpen,
    type,
    file,
}) => {
    const classes = useStyles();
    const DefaultPhoto =
        "https://staging.plassroom.com/static/media/DefaultProfilePhoto.5afa2d20.svg";

    const handleClose = () => {
        setIsImageModalOpen(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isImageModalOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isImageModalOpen}>
                <div className={classes.paper}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <CloseIcon
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={handleClose}
                        />
                    </Box>

                    {type === "profilePhoto" ? (
                        <ProfilePhotoCarousel
                            profilePhotos={_.get(record, "profilePictureIds", [
                                {
                                    location: DefaultPhoto,
                                },
                            ])}
                        />
                    ) : (
                        <img
                            src={
                                type === "criminalCheckReport"
                                    ? file
                                    : _.get(
                                          record,
                                          type === "idPicture"
                                              ? "image_ids[0].location"
                                              : ""
                                      ) || DefaultPhoto
                            }
                            alt="User ID"
                            style={{
                                minHeight: "10rem",
                                maxHeight: "15rem",
                            }}
                        />
                    )}
                </div>
            </Fade>
        </Modal>
    );
};

export default IDPicture;
