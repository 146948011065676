import axios from "axios";
import { useMemo } from "react";

const WithAxios = ({ children }) => {
    useMemo(() => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                return error.response;
            }
        );
    }, []);

    return children;
};

export default WithAxios;
