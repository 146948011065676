// import ReviewList from "./ReviewList";
import upcomingClassesList from './UpcomingClassesList';
import ProductIcon  from '@material-ui/icons/Collections';
import ClassShow from './ClassShow/index';

const resource = {
    icon: ProductIcon,
    list: upcomingClassesList,
    show: ClassShow,
};
export default resource;
