import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const ClassTags = ({ record }) => {
    const classes = useStyles();

    return record ? (
        <span className={classes.main}>
            {record?.configuration?.learning_objectives &&
                record?.configuration?.learning_objectives.map(objective => {
                    return objective ? (
                        <Chip
                            size="small"
                            key={objective}
                            className={classes.chip}
                            label={objective}
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

ClassTags.defaultProps = {
    addLabel: true,
    source: 'Class tags',
};

export default ClassTags;
