import { makeStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import React from "react";
import { Fragment } from "react";
import { DeleteButton } from "react-admin";
import AcceptButton from "./AcceptButton";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        justifyContent: "space-between",
    },
}));

const ReviewEditToolbar = (props) => {
    const {
        basePath,
        record,
        resource,
    } = props;
    const classes = useStyles();
    if (!record) return null;
    return (
        <MuiToolbar className={classes.root}>
            {!record?.deleted_by_admin ? (
                <DeleteButton
                    basePath={basePath}
                    record={record}
                    resource={resource}
                />
            ) : <span style={{color: "red", fontFamily: "sans-serif"}}>Review deleted</span>}
            {!record?.deleted_by_admin && record?.is_reported &&
            record?.reported_req_completed_by_admin === false ? (
                <Fragment>
                    <AcceptButton record={record} />
                </Fragment>
            ) : null}
        </MuiToolbar>
    );
};

export default ReviewEditToolbar;
