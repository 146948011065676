import Avatar from "@material-ui/core/Avatar";
import React from "react";
import _ from "lodash";

const AvatarField = ({ record, size = "25", className }) => {
    const avatarPhoto = _.get(record, "profilePictureIds[0].location", "");

    return record ? (
        <Avatar
            src={`${avatarPhoto}?size=${size}x${size}`}
            style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
            className={className}
        />
    ) : null;
};

export default AvatarField;
