import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/v1/admin";
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: "application/json",
        });
    }
    const { data } = JSON.parse(localStorage.getItem("auth"));
    // console.log(data);
    options.headers.set("Authorization", `Bearer ${data?.accessToken}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { q, role } = params.filter;
        let query = {
            perPage,
            page,
            sortField: field,
            sortDirection: order,
            search: q,
            userType: role,
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        console.log(url, "getList", resource, params, params.filter.role);
        return httpClient(url)
            .then(({ headers, json }) => {
                console.log(json.data.list);
                return {
                    data: json.data.list,
                    total: json.data.pagination.total,
                };
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getOne: (resource, params) => {
        console.log("getOne", resource);
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
            ({ json }) => {
                console.log(json.data);
                return {
                    data: json.data,
                };
            }
        );
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        console.log("getMany", resource);
        return httpClient(url).then(({ json }) => {
            return { data: json };
        });
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get("content-range").split("/").pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: json,
        })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: json }));
    },
};

export default dataProvider;
