import _ from "lodash";
import { Typography, Chip, Box } from "@material-ui/core";
import React from "react";
import {
    DateField,
    FunctionField,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
} from "react-admin";
import EmailPreferencesList from "../../../../data/EmailPreferencesList.json";
import BanUser from "./BanUser";
import ClassReviews from "./ClassReviews";
import StudentDashboard from "./StudentDashboard";
import TransactionHistory from "./TransactionHistory";

const PostTitle = ({ record }) => {
    return <span>{record ? record.studentpreferedname : ""}</span>;
};

const NewsLetterPreferences = ({ record }) => {
    let EmailFields = [];

    const emailPreferences = _.get(record, "emailPreferences", []);

    for (let preference in emailPreferences) {
        if (
            typeof emailPreferences[preference] === "boolean" &&
            emailPreferences[preference]
        ) {
            let title = "";
            title = EmailPreferencesList.find((item) => item.id === preference);
            title = _.get(title, "title");

            if (title)
                EmailFields.push(
                    <Chip
                        label={title}
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                            width: "fit-content",
                        }}
                    />
                );
        }
    }

    EmailFields = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                marginTop: "-0.5rem",
                marginBottom: "-0.5rem",
            }}
        >
            {[...EmailFields]}
        </Box>
    );

    return EmailFields;
};

export const TypographyStyle = {
    marginTop: "1rem",
};

export const EmailLabelStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.8rem",
    marginBottom: "8px",
};

const StudentShow = (props) => {
    return (
        <Show {...props} title={<PostTitle />} hasEdit={false}>
            <SimpleShowLayout>
                <BanUser />
                <TextField source="email" label="Email" />
                <TextField source="name" label="Parent Name" />
                <TextField source="preferedName" label="Preferred Name" />
                <DateField label="Birth Date" source="birthDate" />

                <TextField source="studentname" label="Student Name" />
                <TextField
                    source="studentpreferedname"
                    label="Student Preferred Name"
                />
                <DateField
                    label="Student Birth Date"
                    source="studentbirthdate"
                />
                <NumberField label="Emergency Contact" source="contact" />
                <FunctionField
                    label="Location"
                    source="country"
                    render={(record) => {
                        return record.state && record.country
                            ? `${record.state}, ${record.country}`
                            : "-";
                    }}
                />

                <Typography variant="h6" style={TypographyStyle}>
                    Email Preferences
                </Typography>
                <TextField
                    source="emailPreferences.frequency"
                    label="Email Frequency"
                />
                <Typography variant="body2" style={EmailLabelStyle}>
                    Emails subscribed
                </Typography>
                <NewsLetterPreferences />

                <StudentDashboard />

                <TransactionHistory />

                <ClassReviews />
            </SimpleShowLayout>
        </Show>
    );
};

export default StudentShow;
