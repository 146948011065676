import { Typography, Chip, Box } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import {
    DateField,
    FunctionField,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
} from "react-admin";
import EmailPreferencesList from "../../../../data/EmailPreferencesList.json";
import BanUser from "./BanUser";
import ClassesList from "./ClassesList.js";
import ClassReviews from "./ClassReviews";
import IDPicture from "./IDPicture";
import TeacherTab from "./TeacherTab";
import TeacherDashboard from "./TeacherDashboard";
import TransactionHistory from "./TransactionHistory";

const PostTitle = ({ record }) => {
    return <span>{record ? record.studentpreferedname : ""}</span>;
};

const NewsLetterPreferences = ({ record }) => {
    let EmailFields = [];

    const emailPreferences = _.get(record, "emailPreferences", []);

    for (let preference in emailPreferences) {
        if (
            typeof emailPreferences[preference] === "boolean" &&
            emailPreferences[preference]
        ) {
            let title = "";
            title = EmailPreferencesList.find((item) => item.id === preference);
            title = _.get(title, "title");

            if (title)
                EmailFields.push(
                    <Chip
                        label={title}
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                            width: "fit-content",
                        }}
                    />
                );
        }
    }

    EmailFields = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                marginTop: "-0.5rem",
                marginBottom: "-0.5rem",
            }}
        >
            {[...EmailFields]}
        </Box>
    );

    return EmailFields;
};

const SeePhotoLink = ({
    record,
    type,
    setIsImageModalOpen,
    setType,
    setFile,
}) => {
    let file = null;

    if (type === "criminalCheckReport") {
        const criminalDocuments = _.get(
            record,
            "criminalCheckReport.criminalCheckDocuments",
            []
        );

        if (criminalDocuments.length) {
            const filteredDocument = criminalDocuments.find(
                (document) => document.isLatest
            );

            if (filteredDocument) {
                file = _.get(filteredDocument, "documentId.location");
                setFile(file);
            }
        }
    } else {
        file = _.get(
            record,
            type === "idPicture"
                ? "image_ids[0].location"
                : type === "profilePhoto"
                ? "profilePictureIds"
                : null
        );
    }

    return (
        <Typography
            variant="body2"
            component="a"
            color="secondary"
            style={
                file
                    ? LinkStyle
                    : {
                          color: "rgba(0, 0, 0, 0.54)",
                      }
            }
            onClick={
                file
                    ? () => {
                          setIsImageModalOpen(true);
                          setType(type);
                      }
                    : null
            }
        >
            {file ? "See Photo" : "No File Found"}
        </Typography>
    );
};

export const TypographyStyle = {
    marginTop: "1rem",
};

export const EmailLabelStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.8rem",
    marginBottom: "8px",
};

export const CriminalCheckLabelStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.8rem",
    marginTop: "0.7rem",
    marginBottom: "8px",
};

export const LinkStyle = {
    cursor: "pointer",
};

const TeacherShow = (props) => {
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [type, setType] = useState("");
    const [file, setFile] = useState(null);

    return (
        <Show {...props} title={<PostTitle />} hasEdit={false}>
            <SimpleShowLayout>
                <IDPicture
                    isImageModalOpen={isImageModalOpen}
                    setIsImageModalOpen={setIsImageModalOpen}
                    type={type}
                    file={file}
                />

                <BanUser />

                <TextField source="email" label="Email" emptyText="-" />
                <TextField source="name" label="Full Name" emptyText="-" />
                <TextField
                    source="preferedName"
                    label="Preferred Name"
                    emptyText="-"
                />

                <DateField label="Birth Date" source="birthDate" />
                <NumberField label="Emergency Contact" source="contact" />

                <TextField
                    source="documentType"
                    label="ID Picture"
                    emptyText="-"
                />
                <SeePhotoLink
                    type="idPicture"
                    setIsImageModalOpen={setIsImageModalOpen}
                    setType={setType}
                    setFile={setFile}
                />

                <Typography variant="body2" style={CriminalCheckLabelStyle}>
                    Criminal Check Document
                </Typography>
                <SeePhotoLink
                    type="criminalCheckReport"
                    setIsImageModalOpen={setIsImageModalOpen}
                    setType={setType}
                    setFile={setFile}
                />

                <Typography variant="h6" style={TypographyStyle}>
                    Store Profile
                </Typography>
                <TextField
                    source="preferedName"
                    label="Display Name"
                    emptyText="-"
                />
                <TextField
                    source="storeDescription"
                    label="Introduction"
                    emptyText="-"
                />
                <FunctionField
                    label="Location"
                    source="country"
                    render={(record) => {
                        return record.state && record.country
                            ? `${record.state}, ${record.country}`
                            : "-";
                    }}
                />
                <Typography variant="body2" style={CriminalCheckLabelStyle}>
                    Profile Photo
                </Typography>
                <SeePhotoLink
                    type="profilePhoto"
                    setIsImageModalOpen={setIsImageModalOpen}
                    setType={setType}
                    setFile={setFile}
                />

                <Typography variant="h6" style={TypographyStyle}>
                    Email Preferences
                </Typography>
                <TextField
                    source="emailPreferences.frequency"
                    label="Email Frequency"
                    emptyText="-"
                />
                <Typography variant="body2" style={EmailLabelStyle}>
                    Emails subscribed
                </Typography>
                <NewsLetterPreferences />

                <Typography variant="h6" style={TypographyStyle}>
                    Account Balance
                </Typography>
                <NumberField
                    label="Amount in wallet"
                    source="commission"
                    options={{
                        style: "currency",
                        currency: "EUR",
                    }}
                />
                <NumberField
                    label="Amount available for payout"
                    source="commission"
                    options={{
                        style: "currency",
                        currency: "EUR",
                    }}
                />

                <TeacherTab />
            </SimpleShowLayout>
        </Show>
    );
};

export default TeacherShow;
