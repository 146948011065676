import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { MinutesToHours } from "../../../../utils/MinutesToHours";
import { TypographyStyle } from "./index";

const useStyles = makeStyles({
    rightAlignedCell: {
        textAlign: "right",
    },

    transactionOut: {
        color: "rgb(227, 107, 45)",
    },

    transactionIn: {
        color: "rgb(0, 43, 74)",
    },
});

const TransactionHistory = (props) => {
    const classes = useStyles();
    const { record } = props;

    const getDate = (time) => {
        if (time) {
            return moment.utc(time).format("DD MMM YYYY");
        } else {
            return "-";
        }
    };

    const getTime = (time) => {
        if (time) {
            return moment.utc(time).format("hh:mm A");
        } else {
            return "-";
        }
    };

    return (
        <Fragment>
            <Typography variant="h6" style={TypographyStyle}>
                Transaction History
            </Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>

                        <TableCell>Name of Class</TableCell>

                        <TableCell className={classes.rightAlignedCell}>
                            Time (Duration)
                        </TableCell>

                        <TableCell className={classes.rightAlignedCell}>
                            Transacted (£) in/
                            <span className={classes.transactionOut}>out</span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.transactionHistory &&
                    record.transactionHistory.length
                        ? record.transactionHistory.map((transaction) => {
                              return (
                                  <TableRow key={transaction._id}>
                                      <TableCell>
                                          {_.get(
                                              transaction,
                                              "refund.status"
                                          ) === "succeeded"
                                              ? getDate(
                                                    _.get(
                                                        transaction,
                                                        "refund.date"
                                                    )
                                                )
                                              : getDate(
                                                    _.get(
                                                        transaction,
                                                        "updatedAt"
                                                    )
                                                )}
                                      </TableCell>

                                      <TableCell>
                                          {_.get(
                                              transaction,
                                              "sessionId.class_name",
                                              "-"
                                          )}
                                      </TableCell>

                                      <TableCell
                                          className={classes.rightAlignedCell}
                                      >
                                          {`${getTime(
                                              _.get(
                                                  transaction,
                                                  "sessionId.configuration.session_start_time"
                                              )
                                          )} (${MinutesToHours(
                                              _.get(
                                                  transaction,
                                                  "sessionId.configuration.session_duration",
                                                  "-"
                                              )
                                          )})`}
                                      </TableCell>

                                      <TableCell
                                          className={`${
                                              classes.rightAlignedCell
                                          } ${
                                              transaction.paymentStatus ===
                                              "succeeded"
                                                  ? classes.transactionOut
                                                  : transaction.paymentStatus ===
                                                    "refund_succeeded"
                                                  ? classes.transactionIn
                                                  : null
                                          }`}
                                      >
                                          {transaction.paymentStatus ===
                                          "canceled"
                                              ? "Failed transaction"
                                              : _.get(
                                                    transaction,
                                                    "totalPayableAmount"
                                                )
                                              ? Math.round(
                                                    (transaction.totalPayableAmount +
                                                        Number.EPSILON) *
                                                        100
                                                ) / 100
                                              : ""}
                                      </TableCell>
                                  </TableRow>
                              );
                          })
                        : null}
                </TableBody>
            </Table>
        </Fragment>
    );
};

export default TransactionHistory;
