import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TeacherDashboard from "./TeacherDashboard";
import TransactionHistory from "./TransactionHistory";
import ClassesList from "./ClassesList.js";
import ClassReviews from "./ClassReviews";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tabsRoot: {
        backgroundColor: "#e0e0e0",
    },
}));

export default function SimpleTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { record } = props;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return record ? (
        <div className={classes.root}>
            <Tabs
                className={classes.tabsRoot}
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                centered
            >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Transaction History" {...a11yProps(1)} />
                <Tab label="Classes" {...a11yProps(2)} />
                <Tab label="Class Reviews" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <TeacherDashboard record={record} />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <TransactionHistory record={record} />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <ClassesList record={record} />
            </TabPanel>

            <TabPanel value={value} index={3}>
                <ClassReviews record={record} />
            </TabPanel>
        </div>
    ) : null;
}
