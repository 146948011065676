export const ValidatePasswords = ({ password, confirm_password }) => {
    const errors = {};

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            "The password confirmation is not the same as the password.",
        ];
    }

    return errors;
};
