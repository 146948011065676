import polyglotI18nProvider from "ra-i18n-polyglot";
import React from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./api/dataProvider";
import authProvider from "./authProvider";
import Dashboard from "./containers/Dashboard";
import Layout from "./containers/Layout/Layout";
import login from "./containers/Login";
import StudentsList from "./containers/Users/Students";
import TeachersList from "./containers/Users/Teachers";
import englishMessages from "./utils/i18n/en";
import reviews from "./containers/Reviews";
import Classes from "./containers/ClassList/index";
import upcomingClasses from "./containers/UpcomingClasses";

const i18nProvider = polyglotI18nProvider(() => {
    return englishMessages;
}, "en");

const App = () => {
    return (
        <Admin
            dashboard={Dashboard}
            loginPage={login}
            layout={Layout}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
        >
            <Resource
                name="students"
                options={{
                    label: "Students",
                }}
                {...StudentsList}
            />
            <Resource
                name="teachers"
                options={{
                    label: "Teachers",
                }}
                {...TeachersList}
            />
            <Resource
                name="reviews"
                options={{
                    label: "Reviews",
                }}
                {...reviews}
            />
            <Resource
                name="classes"
                options={{
                    label: "Class List",
                }}
                {...Classes}
            />
            <Resource
                name="upcomingClasses"
                options={{
                    label: "Upcoming Classes",
                }}
                {...upcomingClasses}
            />
        </Admin>
    );
};

export default App;
