import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import {
    // Link,
    FunctionField,
    TextField,
    DateField,
    EditContextProvider,
    SimpleForm,
    TextInput,
    useEditController,
    ReferenceField,
} from "react-admin";
import ReviewEditToolbar from "./ReviewEditToolbar";
import StarRatingField from "./StarRatingField";
import { styles } from "./../Users/Students/StudentCreate/index";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "1em",
    },
    form: {
        [theme.breakpoints.up("xs")]: {
            width: 400,
        },
        [theme.breakpoints.down("xs")]: {
            width: "100vw",
            marginTop: -30,
        },
    },
    inlineField: {
        display: "inline-block",
        width: "50%",
    },
    textInput: {
        color: "black",
        overflow: "auto",
    },
}));

const ReviewEdit = ({ onCancel, ...props }) => {
    // const {supporting_attachments} = props;
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const record = controllerProps?.record;
    // const reportReason = controllerProps?.record?.reason_of_report;
    if (!controllerProps.record) {
        return null;
    }
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Review Details
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    className={classes.form}
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    // save={controllerProps.save}
                    version={controllerProps.version}
                    redirect="list"
                    resource="reviews"
                    toolbar={<ReviewEditToolbar />}
                >
                    {/* <CustomerReferenceField
                        formClassName={classes.inlineField}
                    />
                    <ProductReferenceField
                        formClassName={classes.inlineField}
                    /> */}
                    {/* <ReferenceField
                        source="id"
                        reference="students"
                        label="Parent name"
                        link={false}
                        {...props}
                    >
                        <TextField
                            source="parentName"
                            formClassName={classes.inlineField}
                        />
                    </ReferenceField> */}
                    <FunctionField
                        label="Parent name"
                        source="parentName"
                        render={(record) => {
                            return (
                                <Link
                                    to={`/students/${record?.parentId}/show`}
                                    style={{textDecoration: "none"}}
                                >
                                    <TextField
                                        label="Parent name"
                                        source="parentName"
                                        formClassName={classes.inlineField}
                                    />
                                </Link>
                            );
                        }}
                    />

                    {/* <Link to={`/teachers/619d4267ebea27f251039aa9/show`}>
                        <TextField
                            label="Parent name"
                            source="parentName"
                            formClassName={classes.inlineField}
                        />
                    </Link> */}
                    <TextField
                        label="Class name"
                        source="className"
                        formClassName={classes.inlineField}
                    />
                    <FunctionField
                        label="Teacher name"
                        source="teacherName"
                        render={(record) => {
                            return (
                                <Link
                                    to={`/teachers/${record?.teacherId}/show`}
                                    style={{textDecoration: "none"}}
                                >
                                    <TextField
                                        label="Teacher name"
                                        source="teacherName"
                                        formClassName={classes.inlineField}
                                    />
                                </Link>
                            );
                        }}
                    />
                    {/* <TextField
                        label="Teacher name"
                        source="teacherName"
                        formClassName={classes.inlineField}
                    /> */}
                    <StarRatingField
                        formClassName={classes.inlineField}
                        type="class_rate"
                        label="Class Ratings"
                    />
                    <StarRatingField
                        formClassName={classes.inlineField}
                        type="teacher_rate"
                        label="Teacher Ratings"
                    />
                    <DateField
                        source="createdAt"
                        formClassName={classes.inlineField}
                        label="Review on"
                    />
                    {/* <FunctionField
                        label="Review"
                        source="review"
                        render={(record) => {
                            return record.review ? `${record.review}` : "-";
                        }}
                    /> */}
                    {/* <TextField
                        source="reason_of_report"
                        label="Reason of report"
                    /> */}

                    <TextInput
                        source="review"
                        maxRows={15}
                        multiline
                        fullWidth
                        disabled
                        // style={{color: "black"}}
                        formClassName={styles.textInput}
                    />
                    {record?.reason_of_report && (
                        <>
                            <TextInput
                                source="reason_of_report"
                                maxRows={15}
                                multiline
                                fullWidth
                                disabled
                                // style={{color: "black"}}
                                formClassName={styles.textInput}
                            />
                            {record?.supporting_attachments.length > 0 && (
                                <>
                                    <p style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                        Supporting images:
                                    </p>
                                    {record?.supporting_attachments.map(
                                        (location) => {
                                            return (
                                                <img
                                                    alt="img"
                                                    src={location?.location}
                                                    width="120"
                                                    height="100"
                                                    // title="Img"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.open(
                                                            location?.location,
                                                            "_blank"
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                </>
                            )}
                        </>
                    )}
                </SimpleForm>
            </EditContextProvider>
        </div>
    );
};

export default ReviewEdit;
