import axios from "axios";
import _ from "lodash";

axios.interceptors.request.use((config) => {
    let data = localStorage.getItem("auth");

    if (data) {
        data = JSON.parse(data);
        console.log(data);

        const token = _.get(data, "data.accessToken");
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
    }

    return config;
});

axios.interceptors.response.use((response) => {
    // * Change the response according to the response from server.
    if ([200].includes(response.status)) {
        return response;
    } else {
        throw response;
    }
});

export const request = async ({
    url,
    method = "GET",
    params,
    data,
    headers,
}) => {
    const BASE_URL = process.env.REACT_APP_BACKEND_URL + "/api/v1/admin";

    const res = await axios.request({
        url: BASE_URL + url,
        method,
        params,
        data,
        headers,
    });

    return res;
};
